import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  duration = 5000;

  constructor(private snackBar: MatSnackBar, private zone: NgZone) { }

  public openSuccess(successMessage?: string) {
    const message = successMessage ? successMessage : 'Successfully saved.';
    this.zone.run(() => {
      this.snackBar.open(message, '', {
        duration: this.duration,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['top-success-snackbar']
      });
    });
  }

  public openError(errorMessage?: string) {
    const message = errorMessage ? errorMessage : 'Something went wrong. Please reload page. / Бірдеңе дұрыс болмады. Кейінірек қайталап көріңіз';
    this.zone.run(() => {
      this.snackBar.open(message, '', {
        duration: this.duration,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['top-error-snackbar']
      });
    });
  }

  public openWarning(warningMessage?: string) {
    const message = warningMessage ? warningMessage : 'Something went wrong.';
    this.zone.run(() => {
      this.snackBar.open(message, '', {
        duration: this.duration,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: ['top-warning-snackbar']
      });
    });
  }

  public open(message: string, action: string | undefined, duration: any, style: string) {
    this.zone.run(() => {
      this.snackBar.open(message, action, {
        duration,
        panelClass: [style],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    });
  }
}
